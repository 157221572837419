import React from 'react';
import { Row, Grid, Col } from 'react-bootstrap';

export const Footer = props => {
    const year = new Date().getFullYear();
    return (
        <footer className="text-center">
            <div className="footer-above">
                <Grid>
                    <Row>
                        <Col md={12} className="footer-col">
                            <h3>Around the Web</h3>
                            <ul className="list-inline">
                                {props.children.map((child, i) => (
                                    <li key={i}>{child}</li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </Grid>
            </div>
            <div className="footer-below">
                <Grid>
                    <Row>
                        <Col lg={12}>
                            Copyright © {props.copyright} {props["copyright-year"]}-{year}
                        </Col>
                    </Row>
                </Grid>
            </div>
        </footer>
    );
}

export default Footer;