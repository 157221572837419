import React from 'react';
import { Nav, Navbar, NavItem } from 'react-bootstrap';
import classNames from 'classnames';

export class Header extends React.Component {

    constructor(props) {
        super(props);
        this.onScroll = this.onScroll.bind(this);
        this.isScrolledDown = this.isScrolledDown.bind(this);

        this.state = {
            scrolledDown: this.isScrolledDown()
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", this.onScroll);
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.onScroll);
    }

    render() {
        const { scrolledDown } = this.state;
        return (
            <Navbar fixedTop collapseOnSelect={true} className={classNames({ "navbar-shrink": scrolledDown })}>
                {this.props.children}
            </Navbar>
        );
    }

    onScroll() {
        const scrolledDown = this.isScrolledDown();
        this.setState({ scrolledDown });
    }

    isScrolledDown() {
        return (window.pageYOffset || document.documentElement.scrollTop) >= this.props.threshold;
    }
}

Header.Brand = props => {
    const onClick = event => {
        event.stopPropagation();
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    };
    return (
        <Navbar.Header>
            <Navbar.Brand>
                <a href="/" onClick={onClick}>{props.children}</a>
            </Navbar.Brand>
            <Navbar.Toggle />
        </Navbar.Header>
    );
};

Header.Links = props => (
    <Navbar.Collapse>
        <Nav pullRight>
            {props.children}
        </Nav>
    </Navbar.Collapse>
);

Header.Link = props => {
    const onClick = event => {
        event.preventDefault();
        const id = props.href.replace("#", '');
        const anchor = document.getElementById(id);
        const offsetTop = anchor.getBoundingClientRect().top + window.pageYOffset;
        window.scroll({
            top: offsetTop,
            behavior: 'smooth'
        });
    };
    return (
        <NavItem eventKey={props.eventKey} href={props.href} onClick={onClick}>{props.children}</NavItem>
    );
};

export default Header;