import React from 'react';
import { Row, Grid, Col } from 'react-bootstrap';

export const Intro = props => (
    <header>
        <Grid>
            <Row>
                <Col lg={12}>
                    <div className="profile-img">
                        <img className="img-responsive" src={props.img} alt="img" />
                    </div>
                    <div className="intro-text">
                        <span className="name">{props.name}</span>
                        <hr className="star-light" />
                        <span className="skills">{props.title}</span>
                    </div>
                </Col>
            </Row>
        </Grid>
    </header>
);

export default Intro;