import React from 'react';
import { Row, Grid, Col } from 'react-bootstrap';
import classNames from "classnames";

export const Section = props => (
    <section id={props.id} key={props.key} className={props.className}>
        <Grid>
            <Row>
                <Col lg={12} className="text-center">
                    <h2>{props.name}</h2>
                    <hr className={classNames({ "star-primary": props.primary }, { "star-light": !props.primary })} />
                </Col>
            </Row>
            <Row>
                {props.children}
            </Row>
        </Grid>
    </section>
);

export default Section;
