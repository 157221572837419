import React from 'react';
import classNames from "classnames";

export const Social = props => (
    <a href={props.href} className={classNames("btn-social", "btn-outline")} rel="noopener">
        <i className={classNames("fa", "fa-fw", `fa-${props.type}`)} />
    </a>
);

export default Social;
