import React from 'react';
import numberToText from 'number-to-text';
import 'number-to-text/converters/en-us';

export const Experience = props => {
    const year = new Date().getFullYear();
    const diff = year - props.since;
    return (
        <span>{numberToText.convertToText(diff, { case: "lowerCase" })} years of experience</span>
    );
}

export default Experience;
