import React, { Component } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { Section } from "..";
import classNames from "classnames";

export const Portfolio = props => (
    <Section id="portfolio" name="Portfolio" key={props.key} className={props.className} primary={true}>
        {props.children}
    </Section>
);

Portfolio.Item = class Item extends Component {

    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);

        this.state = {
            show: false
        };
    }

    render() {
        return (
            <Col sm={4} className="portfolio-item">
                <Button bsStyle="link" className="portfolio-link" onClick={this.showModal}>
                    <div className="caption">
                        <div className="caption-content">
                            <i className="fa fa-search-plus fa-3x" />
                        </div>
                    </div>
                    <img className="img-responsive" src={this.props.img} alt={this.props.name} />
                </Button>
                <Modal className="portfolio-modal" show={this.state.show} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title componentClass="h2">{this.props.name}</Modal.Title>
                        <hr className="star-primary" />
                    </Modal.Header>
                    <Modal.Body>
                        <img className={classNames("img-responsive", "img-centered")} src={this.props.img} alt={this.props.name} />
                        {this.props.children}
                        <ul className={classNames("list-inline", "item-details")}>
                            {this.props.year && (
                                <li>
                                    <span>Year</span>:&nbsp;
                                    <strong>{this.props.year}</strong>
                                </li>
                            )}
                            {this.props.href && (
                                <li>
                                    <span>Link</span>:&nbsp;
                                    <strong>
                                        <a href={this.props.href} rel="noopener">{this.props.href}</a>
                                    </strong>
                                </li>
                            )}
                        </ul>
                    </Modal.Body>
                </Modal>
            </Col>
        );
    }

    showModal() {
        this.setState({ show: true });
    }

    hideModal() {
        this.setState({ show: false });
    }
}

export default Portfolio;
