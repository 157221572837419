import React from 'react';
import { CodeExample, Experience, Social } from '.';
import { About, Footer, Header, Intro, Portfolio } from './sections';
import leagueMatchupImg from '../static/img/portfolio/leaguematchup.png';
import testTubeImg from '../static/img/portfolio/test-tube.png';
import wrench from '../static/img/portfolio/website-monkey-wrench.png';
import profileImg from '../static/img/profile.jpg';

export const App = _ => (
    <div>
        <Header threshold={100}>
            <Header.Brand>FinickyZone</Header.Brand>
            <Header.Links>
                <Header.Link eventKey="1" href="#portfolio">Portfolio</Header.Link>
                <Header.Link eventKey="2" href="#about">About</Header.Link>
            </Header.Links>
        </Header>
        <Intro name="Nicholas Bérubé" title="Software Integrator and Designer" img={profileImg} />
        <Portfolio>
            <Portfolio.Item name="CRM JavaScript Linq query expression" img={testTubeImg} year={2014}>
                <p>With this library, you can easily create Ajax soap requests to CRM with the same syntax as Linq in C#.</p>
                <p>You'll no longer need to extract your query from an advanced find and your JavaScript Web resources would be much more understandable.</p>
                <br />
                <CodeExample language="javascript">
                    {`// Active accounts
var results = new Query.Soap("account")
                       .where("statecode", Query.enuOperator.equal, 0)
                       .select(["name",
                                "address1_city",
                                "address1_country",
                                "address1_line1",
                                "address1_postalcode",
                                "address1_telephone1"])
                       .toArray();

// Active accounts with their primary contact
var results = new Query.Soap("account")
                       .join("primarycontact", "contact", "contactid", "primarycontactid")
                       .where("statecode", Query.enuOperator.equal, 0)
                       .select(["name",
                                "primarycontact.fullname",
                                "primarycontact.telephone1"])
                       .toArray();

// First account with its primary contact from montreal
var result = new Query.Soap("account")
                      .join("primarycontact", "contact", "contactid", "primarycontactid")
                      .where("statecode", Query.enuOperator.equal, 0)
                      .where("primarycontact.address1_city", Query.enuOperator.equal, "montreal")
                      .select(["name",
                               "primarycontact.fullname",
                               "primarycontact.telephone1"])
                      .firstOrDefault();`}
                </CodeExample>
            </Portfolio.Item>
            <Portfolio.Item name="League Matchup" img={leagueMatchupImg} year={2014} href="https://leaguematchups.azurewebsites.net">
                <p>Find the best way to win your games by finding the strongest champion against the enemy.</p>
                <p> League Matchups shows you the top champions at the moment and, at the same time, give you their strongest opponents.</p>
            </Portfolio.Item>
            <Portfolio.Item name="CRM extended JavaScript Library" img={wrench} year={2012}>
                <p>Extend the current Xrm SDK with usefull features. </p>
                <CodeExample language="javascript">
                    {`// Get multiples attributes or control
var controls = Exrm.getControl("address1_city",
                               "address1_country",
                               "address1_line1",
                               "address1_postalcode",
                               "address1_telephone1");

// Do multiple actions with one call
controls.setDisabled(false);
controls.setVisible(true);

// You can also select a specific attribute or control and execute the default xrm behavior
controls.address1_city.getAttribute().setValue(null);
controls["address1_city"].getAttribute().setValue(null);

// Change the value of multiple attributes at once
Exrm.getAttribute("address1_city",
                  "address1_country",
                  "address1_line1",
                  "address1_postalcode",
                  "address1_telephone1")
    .setValue(null);`}
                </CodeExample>
            </Portfolio.Item>
        </Portfolio>
        <About>
            <p>Mr. Berube has about <Experience since={2010} /> in information technology.</p>
            <p>He has held positions as an analyst-programmer and has been able to contribute its expertise in development for major companies such as the Business Bank of Canada, Keyrus Canada, Utilicase and PSP Investments.</p>
            <p>He has also worked with various technology environments such as .Net / C#, Typescript, Javascript and Microsoft CRM 2011/365.</p>
        </About>
        <Footer copyright="FinickyZone" copyright-year={2011}>
            <Social href="https://github.com/finickyflame" type="github" />
            <Social href="https://facebook.com/FinickyFlame" type="facebook" />
            <Social href="https://twitter.com/Finickyflame" type="twitter" />
            <Social href="https://www.linkedin.com/in/nicholas-b%C3%A9rub%C3%A9-686b0645" type="linkedin" />
        </Footer>
    </div>
);
export default App;