import React from 'react';
import { Col } from 'react-bootstrap';
import { Section } from "..";
import classNames from "classnames";

export const About = props => (
    <Section id="about" name="About" key={props.key} className={classNames(props.className, "success")}>
        <Col lg={8} lgOffset={2}>
            {props.children}
        </Col>
    </Section>
);