import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';

export const CodeExample = props => (
    <div>
        <h5>Examples:</h5>
        <Row>
            <Col md={10} mdOffset={1}>
                <SyntaxHighlighter language={props.language} style={okaidia}>{props.children}</SyntaxHighlighter>
            </Col>
        </Row>
    </div>
);

export default CodeExample;
